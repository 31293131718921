import { Teams } from './../entities/teams'

var teamSelect = $('.team-select'),
  teamUsersGetButton = $('.team-users-get')

$(document).ready(function () {
  //Team selection event
  if (teamSelect.length) {
    $(document).on('change', '.team-select', function (e) {
      e.preventDefault()
      const option = $('option:selected', this)

      Teams.select($(option).data('teamId'))
    })
  }

  Teams.initInviteForm()

  //Team user role selection
  $(document).on('change', '.user-team-role-select', function () {
    const userId = $(this).closest('.users-block').data('userId')
    if (userId) {
      Teams.setUserPermissions(userId, $(this).val())
    }
  })

  //Team pdf reports subscription
  $(document).on('change', '.subscription-list #report_user', function () {
    const formData = $('.subscription-list form').serialize()
    Teams.updateReportUsers(formData)
  })

  //Get team users event
  if (teamUsersGetButton.length) {
    $(document).on('click', '.team-users-get', function (e) {
      e.preventDefault()
      Teams.getUsers()
    })
  }

  //Here we can display for each user his current team tasks report
  if ($('.reports-users-list').length) {
    $(document).on('click', '.reports-users-list .show-report', function (e) {
      e.preventDefault()
      const userId = $(this).data('userId')
      if (userId) {
        Teams.loadUserReport(userId)
      }
    })
  }

  // enable/disable user globally on team options page
  $(document)
    .find('#teammates-box')
    .find('.ios8-switch')
    .on('click', function () {
      const userId = $(this).data('user-id')
      if ($(this).is(':checked')) {
        Teams.toggleUserState(userId, true)
        $(this).closest('.table-row').addClass('user-active')
      } else if ($(this).is(':not(:checked)')) {
        Teams.toggleUserState(userId, false)
        $(this).closest('.table-row').removeClass('user-active')
      }
    })

  $(document).on('change', '#team-switcher', function (e) {
    e.preventDefault()
    Teams.loadTeamOptions($(this).find(':selected').data('team-id'))
  })

  $('.change_status_role').select2({
    minimumResultsForSearch: 4,
    width: '100%',
  })

  $('.change_status_role').on('change', function (e) {
    Teams.toggleUserRole($(this).closest('form').serializeArray(), $(this).data('user-id'))
  })

  $('[data-toggle="tooltip"]').tooltip()
})
